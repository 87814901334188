<template>
  <div class="detail-page">
    <div class="handle-area">
      <a-button class="margin-l40 width-120" type="danger" icon="delete" @click="delItem" v-has-permission="['deleteCustomer']">删除</a-button>
    </div>
    <a-row :gutter="10">
      <a-col span="13">
        <a-card title="客户信息" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editCustomer']">保存</a-button>
          </div>
          <a-form-model
              ref="infoForm"
              labelAlign="right"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 17 }"
              :model="detail"
              :rules="infoRules"
              :hideRequiredMark="true"
          >
            <a-row :gutter="10">
              <a-col span="12">
                <a-form-model-item label="客户名称" prop="customerName">
                  <a-input placeholder="请输入客户名称" v-model="detail.customerName" :disabled="!canModify"/>
                </a-form-model-item>
                <a-form-model-item  label="客户级别" prop="customerLevel">
                  <a-select v-model="detail.customerLevel" placeholder="请选择客户级别" :disabled="!canModify">
                    <a-select-option :value="1">重要</a-select-option>
                    <a-select-option :value="2">普通</a-select-option>
                    <a-select-option :value="3">非优</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="联系电话" prop="phone">
                  <a-input placeholder="请输入联系电话" v-model="detail.phone" :disabled="!canModify"/>
                </a-form-model-item>
                <a-form-model-item label="邮箱" prop="email">
                  <a-input placeholder="请输入邮箱" v-model="detail.email" :disabled="!canModify" />
                </a-form-model-item>
                <a-form-model-item label="省/城市" prop="province">
                  <District :level="2" @change="getDistrict" v-model="detail.province" :disabled="!canModify"></District>
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="address">
                  <a-textarea placeholder="请输入详细地址" v-model="detail.address" :auto-size="{ minRows: 3, maxRows: 5 }" :disabled="!canModify" />
                </a-form-model-item>
                <a-form-model-item label="网址" prop="website">
                  <a-input placeholder="请输入网址" v-model="detail.website" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
              <a-col span="12">
                <a-form-model-item label="客户来源" prop="customerFrom">
                  <a-textarea placeholder="请输入客户来源" v-model="detail.customerFrom" style="height: 350px" :disabled="!canModify" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </a-col>
      <a-col span="5">
        <a-card title="关联干系人" :bodyStyle="{'height': '400px', 'overflow-y' : 'auto'}">
          <a-row class="padding-tb10" v-for="(item) in detail.stakeholders" :key="item.id">
            <a-col :span="8" class="font-c9">
              <template v-if="item.stakeholderLevel === 1">主要干系人</template>
              <template v-if="item.stakeholderLevel === 2">干系人</template>：
            </a-col>
            <a-col :span="16">{{ item.stakeholderName }} <span class="margin-l10">{{ item.phone }}</span></a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col span="6">
        <a-card title="合作项目" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <span>{{ detail.cooperProjectNum }}（{{ detail.cooperProjectGoingNum }}）</span>
          </div>
          <div class="line-h30">
            <div class="display-f justify-between" v-for="(item, index) in detail.projectInfos" :key="item.id">
              <span>{{ item.projectName }}</span>
              <span class="font-c9"> {{ projectStatusName[item.projectStatus] }}</span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="18">
        <a-card title="日志">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import { createCustomer, deleteCustomer, getCustomerDetail } from '../../api/customer/customer'
import District from '@/components/district/district'
import { mapState } from 'vuex'

export default {
  name: 'customerDetail',
  components: {
    District
  },
  data () {
    return {
      detail: {},
      infoRules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        customerLevel: [
          { required: true, message: '请选择客户级别', trigger: 'change' }
        ],
        province: [
          { required: true, message: '请选择省/城市', trigger: 'change' }
        ]
      },
      districtList: [],
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
      projectStatusName: ['', '洽谈中', '规划中', '实施中', '验收中', '已完成', '已取消', '已搁置'],
      canModify: false
    }
  },
  mounted () {
    this.getDetail()
    this.canModify = this.authority.editCustomer
  },
  methods: {
    moment,
    getDetail () {
      getCustomerDetail({
        id: this.$route.query.id
      }).then(res => {
        this.detail = res.data
        this.detail.province = [res.data.privince, res.data.city]
      })
    },
    getDistrict (data) {
      this.detail.privince = data[0]
      this.detail.city = data[1]
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteCustomer({
            id: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getMember()
          })
        }
      })
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createCustomer(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
